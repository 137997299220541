import React, { useEffect } from 'react';
import './EventPage.css'; // Assuming you have a separate CSS file for styling

const NxtUp = () => {
  // Dynamically load the Cognito form script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.cognitoforms.com/f/seamless.js";
    script.setAttribute('data-key', '_D4pzfeZVUCsbLYuLXSrew');
    script.setAttribute('data-form', '9');
    script.async = true;
    document.getElementById('cognito-form-container').appendChild(script);
  }, []);

  return (
    <div className="event-page">
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>NXT UP Miami Artist Showcase Competition</h1>
      <section className="event-details nxtup-section">
        <h2>Event Details</h2>
        <p><strong>What:</strong> NXT Up Miami Presents: The Artist Showcase Competition</p>
        <br />
        <p><strong>When:</strong> Wednesday December 11th, Doors 7 PM, 8:30 PM – 12:00 AM</p>
        <br />
        <p><strong>Where:</strong> Da Cave, 13641 NW 7th Ave, North Miami, FL 33168</p>
        <br />
        <p><strong>Hosted By </strong> </p>
        <p>
            <a href="https://www.instagram.com/1331recordz/" target="_blank" rel="noopener noreferrer">
                1331Recordz 
            </a>
            </p>
        <p>
        <a href="https://www.instagram.com/djfivevenoms/" target="_blank" rel="noopener noreferrer">
                DJ Five Venoms
            </a>

        </p>
            
       
        <br/>
        <p><strong>Music By: </strong> 
        <a href="https://www.instagram.com/ebt.k/" target="_blank" rel="noopener noreferrer">
            Scoota
        </a> 
        </p>
      </section>

      <section className="competition-details nxtup-section">
        <h2>Competition Details</h2>
        <p>20 artists will perform their best song (no longer than 4 minutes) in front of 3 music industry judges and compete for prizes valued between $1000-3000.</p>
        <br />
        <p><strong>Judges</strong></p>
        <p>
          <a href="https://www.instagram.com/djaffect/" target="_blank" rel="noopener noreferrer">
            DJ Affect
          </a>
        </p>
        <p>
        <a href="https://www.instagram.com/rojasonthebeat/" target="_blank" rel="noopener noreferrer">
            Rojas On The Beat
          </a>
        </p>
        <p>
        <a href="https://www.instagram.com/nikkimontana/" target="_blank" rel="noopener noreferrer">
            Nikki from 99 Jamz
          </a>
        </p>
        <br />
        <p><strong>Entry Fee:</strong> $100 per artist (all artists will receive 10 tickets valued at $10.00).</p>
      </section>

      <section className="prizes nxtup-section">
        <h2>Prizes</h2>
        <h3>All Competitors</h3>
        <br />
        <p>Each competitor will receive a mini recap video and sponsor swag.</p>
        <br />
        <h3>First Place Winner</h3>
        <div className="prize-text">
          <p>Song added to stage playlist at Rolling Loud</p>
          <p>DJ Five Venoms Music Consultation</p>
          <p>Five hours of studio time at 1331 Recordz Studio (Drip Room)</p>
          <p>Music Distribution Deal</p>
          <p>Promotional/short form content video</p>
          <p>...additional prizes</p>
        </div>
        <br />
        <h3>Second Place Winner</h3>
        <div className="prize-text">
          <p>Two hours studio time at 1331 Recordz Studio (Drip Room)</p>
          <p>Promotional/short form content video</p>
          <p>...additional prizes</p>
        </div>
        <br />
        <h3>Third Place Winner</h3>
        <div className="prize-text">
          <p>Two hours studio time at 1331 Recordz Studio (Drip Room)</p>
          <p>Custom piece by Scoota</p>
          <p>...additional prizes</p>
        </div>
      </section>

      <section className="judging-criteria nxtup-section">
        <h2>Judging Criteria</h2>
        <p>Each artist will be scored out of 10 points in the following categories:</p>
        <div className="criteria-text">
          <p><strong>Originality:</strong> Creativity and uniqueness of the artist</p>
          <p><strong>Stage Presence:</strong> Energy, confidence, and crowd interaction</p>
          <p><strong>Technical Ability:</strong> Vocal skill, lyrical ability</p>
          <p><strong>Audience Reaction:</strong> Level of engagement with the performance</p>
        </div>
      </section>

      <section className="register nxtup-section">
        <h2>Register</h2>
        <div id="cognito-form-container"></div> {/* This is where the form will be injected */}
      </section>
    </div>
  );
};

export default NxtUp;
